import React from "react"

// import { Link } from "gatsby"
import { Box, Flex } from "@chakra-ui/core"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"

function Footer({ path }) {
  const { t } = useTranslation()

  return (
    <Box w="100%" bg="red">
      <Flex
        pos="relative"
        zIndex="10"
        justify="flex-end"
        h={["9em", "6em", "3em"]}
        align="center"
        color="white"
        flexShrink="0"
        fontSize={["4xl", "3xl", "3xl"]}
        display={["none", "none", "flex"]}
        maxWidth="1920px"
      >
        <Link
          to={`/${t("datenschutz")}`}
          state={{ path: t("datenschutz"), prevPath: path }}
        >
          <Box>{t("Datenschutz")}</Box>
        </Link>
        <Link
          to={`/${t("impressum")}`}
          state={{ path: t("impressum"), prevPath: path }}
        >
          <Box px={["1em", "2em", "1em"]}>{t("Impressum")}</Box>
        </Link>
      </Flex>
    </Box>
  )
}

export default Footer
