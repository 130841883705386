/*
  Navigationbar fixed to left side
*/

import React from "react"

import { Flex, Box } from "@chakra-ui/core"

import PageInformation from "containers/pageInfo"
import BannerLBTB from "components/bannerLBTB"

function Main({ path, children }) {
  return (
    <Flex
      flex="1 0 auto"
      className="main"
      bg="inherit"
      maxWidth="1920px"
      height="100%"
      pos="relative"
    >
      <PageInformation path={path} />
      <Box
        w={["calc(100% - 10em)", "calc(100% - 10em)", "calc(100% - 15em)"]}
        height="100%"
      >
        {children}
      </Box>
      {(path === '/') && <BannerLBTB/>}
    </Flex>
  )
}

export default Main
