/*
  Navigationbar fixed left side
*/

import React from "react"
import { Box, Flex } from "@chakra-ui/core"
import styled from "@emotion/styled"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledBox = styled(Box)`
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: top left;
`

function PageInfo({ path }) {
  const { t } = useTranslation()

  const title = {
    forschung: t("Forschung"),
    research: t("Forschung"),
    buchhandlung: t("Buchhandlung"),
    bookstore: t("Buchhandlung"),
    "das-haus": t("Das Museum"),
    "the-house": t("Das Museum"),
    veranstaltungen: t("Veranstaltungen"),
    events: t("Veranstaltungen"),
    "angebote-des-hauses": t("Angebote des Hauses"),
    "offerings-of-the-house": t("Angebote des Hauses"),
    "fundraising": t("Fundraising"),
    datenschutz: t("datenschutz"),
    "privacy-policy": t("datenschutz"),
    literaturwettbewerb: t("Literaturwettbewerb"),
    "literature-award": t("Literaturwettbewerb"),
    impressum: t("Impressum"),
    imprint: t("Impressum"),
    kontakt: t("Kontakt"),
    contact: t("Kontakt"),
    freundeskreis: t("Freundeskreis und Stiftung"),
    galerie: t("Mediathek"),
    gallery: t("Mediathek"),
  }[path && path.replace(/\/en|\/de|\/|\/|$/g, "")]

  return (
    <Flex
      className="pageInfo"
      direction="column"
      align="flex-start"
      justify="flex-end"
      h="auto"
      flex={["0 0 10em", "0 0 10em", "0 0 15em"]}
    >
      <StyledBox
        pos="fixed"
        zIndex="9"
        bottom={["0em", "0em", "3em"]}
        left={["0.75em", "1em", "2em"]}
        fontSize={["5-5xl", "4xl", "3xl"]}
      >
        {title ? title : t("Willkommen")}
      </StyledBox>
    </Flex>
  )
}

export default PageInfo
