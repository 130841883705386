/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import { Flex } from "@chakra-ui/core"

import Header from "components/header"
import Main from "containers/main"
import Footer from "components/footer"
import "./layout.css"

const Layout = ({ children, path, bgHeader }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Flex direction="column" bg={bgHeader}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        backgroundColor={bgHeader}
        path={path}
      />
      <Main path={path}>{children}</Main>
      <Footer />
    </Flex>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
