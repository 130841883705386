import React, { useState, useEffect } from "react"
import { Flex, Box } from "@chakra-ui/core"

import { useSpring, animated } from "react-spring"
import { Link, useI18next, I18nextContext } from "gatsby-plugin-react-i18next"
import { useScroll } from "react-use-gesture"
import { useActiveLang } from "hooks/useActiveLang"

export function LanguageSwitch({ path }) {
  const [compress, setCompress] = useState(false)

  const styleProps = useSpring({
    transform: compress
      ? "translate3d(0, -15vh, 0)"
      : "translate3d(0, -0vh, 0)",
  })

  let { languages, originalPath } = useI18next()

  let oPath = originalPath

  const en = path && path.includes("/en/")

  if (
    !en &&
    (oPath.includes("veranstaltungen") ||
      oPath.includes("galerie") ||
      oPath.includes("literaturwettbewerb") ||
      oPath.includes("angebote-des-hauses") ||
      oPath.includes("kontakt") ||
      oPath.includes("das-haus"))
  ) {
    oPath = oPath.replace("veranstaltungen", "events")
    oPath = oPath.replace("galerie", "gallery")
    oPath = oPath.replace("kontakt", "contact")
    oPath = oPath.replace("literaturwettbewerb", "literature-award")
    oPath = oPath.replace("angebote-des-hauses", "offerings-of-the-house")
    oPath = oPath.replace("das-haus", "the-house")
  }
  if (
    en &&
    (oPath.includes("events") ||
      oPath.includes("gallery") ||
      oPath.includes("literature-award") ||
      oPath.includes("offerings-of-the-house") ||
      oPath.includes("contact") ||
      oPath.includes("the-house"))
  ) {
    oPath = oPath.replace("events", "veranstaltungen")
    oPath = oPath.replace("gallery", "galerie")
    oPath = oPath.replace("contact", "kontakt")
    oPath = oPath.replace("literature-award", "literaturwettbewerb")
    oPath = oPath.replace("offerings-of-the-house", "angebote-des-hauses")
    oPath = oPath.replace("the-house", "das-haus")
  }

  const activeLang = useActiveLang()

  const bind = useScroll(
    ({ xy: [, y] }) => {
      if (y > 25) {
        setCompress(true)
      }
      if (y <= 25) {
        setCompress(false)
      }
    },
    {
      domTarget: typeof window !== "undefined" && window,
    }
  )

  useEffect(bind, [bind])

  return (
    <>
      <Flex
        fontSize={["4-5xl", "3xl", "2xl"]}
        textTransform="uppercase"
        pr={["1.5em", undefined, "3em"]}
        bottom={["-0.25em", "-0.2em", "-0.1em"]}
        pos="relative"
        letterSpacing="0.02em"
        as={animated.div}
        style={styleProps}
      >
        {languages
          .map((lng, index) => (
            <Box
              key={lng}
              as="span"
              color={activeLang === lng ? "green" : "brown"}
            >
              <Box
                as="span"
                px={[1, undefined, 1]}
                color={!activeLang === lng ? "green" : "brown"}
              >
                {index === 0 ? "|" : ""}
              </Box>
              <Link
                key={lng}
                to={
                  (lng.toLowerCase() === "en" && !en) ||
                  (lng.toLowerCase() === "de" && en)
                    ? oPath
                    : originalPath
                }
                language={lng}
              >
                {lng}
              </Link>
            </Box>
          ))
          .reverse()}
      </Flex>
    </>
  )
}
