import React, { useState, useRef } from "react"
// import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Flex, Icon, Box, List } from "@chakra-ui/core"

// import {
//   disableBodyScroll,
//   enableBodyScroll,
//   clearAllBodyScrollLocks,
// } from "body-scroll-lock"

import {
  animated,
  useTransition,
  useSpring,
  useChain,
  config,
} from "react-spring"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import theme from "root/theme"

const Nav = styled(animated.nav)`
  position: fixed;
  top: 0;
  right: 0;
  min-height: 50%;

  @media screen and (min-width: 48em) {
    min-height: 20vh;
  }

  @media screen and (min-width: 75em) {
    position: absolute;
    top: -1vw;
    right: -1vw;
  }
`

const Menu = ({ path }) => {
  const { t } = useTranslation()

  const menuItems = [
    { name: t("Das Museum"), link: `/${t("das-haus")}/` },
    { name: t("Veranstaltungen"), link: `/${t("veranstaltungen")}/` },
    { name: t("Buchhandlung"), link: `/${t('buchhandlung')}/` },
    { name: t("Mediathek"), link: `/${t("galerie")}/` },
    { name: t("Angebote des Hauses"), link: `/${t("angebote-des-hauses")}/` },
    { name: t("Literaturwettbewerb"), link: `/${t("literaturwettbewerb")}/` },
    //{ name: t("Forschung"), link: `/${t('forschung')}/` },
    { name: t("Freundeskreis und Stiftung"), link: `/${t('freundeskreis')}/` },
    { name: t("Fundraising"), link: `/${t('fundraising')}/` },
    { name: t("Kontakt und Öffnungszeiten"), link: `/${t("kontakt")}/` },
    { name: t("Impressum"), link: `/${t("impressum")}/` },
    { name: t("Datenschutz"), link: `/${t("datenschutz")}/` },
  ]

  // const menuItems = [{ name: "das Daus", link: `/das-haus/` }]

  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)

  const checkLink = link => {
    if (link === path) setShowMenu(false)
  }

  // Set refs - required for useChain
  const navRef = useRef()
  const liRef = useRef()
  ///

  // Setup animation for nav element
  const springProps = useSpring({
    ref: navRef,
    config: config.default,
    from: { opacity: 0, transform: "translateX(100vw)" },
    opacity: showMenu ? 1 : 0,
    transform: showMenu ? "translateX(0vw)" : "translateX(100vw)",
  })
  ///

  // Setup animations for nav items
  const liTransition = useTransition(showMenu ? menuItems : [], {
    keys: item => item.name,
    ref: liRef,
    trail: 400 / menuItems.length,
    from: { opacity: 0, transform: "translateY(1em)" },
    enter: { opacity: 1, transform: "translateY(0em)" },
    leave: { opacity: 0, transform: "translateY(-1em)" },
  })
  ///

  // On showMenu, start with nav animationm then nav items
  useChain(
    showMenu ? [navRef, liRef] : [liRef, navRef],
    showMenu ? [0, 0.5] : [0, 0.4]
  )
  ///

  return (
    <Flex
      pos="relative"
      justify="flex-end"
      top={["-0.5em", "-0.48em", "-0.5em"]}
    >
      <Icon
        name="menu"
        color="red"
        h={["3.1em", "2.6em", "2em"]}
        w="auto"
        cursor="pointer"
        css={css`
          @media (hover) {
            &:hover {
              color: ${theme.colors.green};
            }
          }
        `}
        onClick={toggleMenu}
      />
      <Nav
        style={{
          ...springProps,
        }}
      >
        <Box
          bg="red"
          h={["100vh", "100vh", "auto"]}
          w={["100vw", "100vw", "30vw"]}
          pos="absolute"
          top="-1vw"
          right={["0", "0", "-1vw"]}
        >
          <Icon
            name="cross"
            pos="absolute"
            right={["4em", "5vw", "1vw"]}
            top={["5em", "5vw", "1vw"]}
            height={["5vw", "2.5em", "2em"]}
            width="auto"
            color="white"
            onClick={toggleMenu}
            cursor="pointer"
            css={css`
              @media (hover) {
                &:hover {
                  color: ${theme.colors.brown};
                }
              }
            `}
          />
          <List
            styleType="none"
            color="white"
            fontSize={["5-5xl", "3.5vh", "3xl"]}
            pt={["15vw", "15vh", "3.5em"]}
            pl={["3em", "9vw", "0.5em"]}
            pb={["4vw", "4vw", "0.2em"]}
            lineHeight={["2em", "2em", "1.1em"]}
          >
            {liTransition((props, { name, link }) => (
              <animated.li style={props}>
                <Box
                  cursor="pointer"
                  pt={name.toLowerCase() === "impressum" && "1.5em"}
                  display={
                    name.toLowerCase() === "impressum" ||
                    name.toLowerCase() === "imprint" ||
                    name.toLowerCase() === "datenschutz"
                      ? ["block", "block", "none"]
                      : "block"
                  }
                  css={css`
                    @media (hover) {
                      &:hover {
                        color: ${theme.colors.brown};
                      }
                    }
                  `}
                  onClick={() => checkLink(link)}
                >
                  <Link to={link} state={{ prevPath: path }}>
                    {name}
                  </Link>
                </Box>
              </animated.li>
            ))}
          </List>
        </Box>
      </Nav>
    </Flex>
  )
}

export default Menu
