import { useContext } from "react"
import { I18nextContext } from "gatsby-plugin-react-i18next"

export const useActiveLang = () => {
  // const [activeLanguage, setActiveLanguage] = useState("")
  const languageContext = useContext(I18nextContext)
  const activeLanguage = languageContext.language

  // useEffect(() => {
  //   setActiveLanguage(languageContext.language)
  // }, [languageContext])

  return activeLanguage
}

export default useActiveLang
