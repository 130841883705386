import { LanguageSwitch } from "./languageSwitch"
import { Logo } from "./logo"
import React, { useState } from "react"
import { Flex } from "@chakra-ui/core"

import Menu from "containers/menu"

const Header = ({ backgroundColor = "white", path }) => {
  const [compress, setCompress] = useState(false)

  return (
    <Flex
      className="header"
      as="header"
      justify="space-between"
      pos="fixed"
      top="0"
      w="100%"
      zIndex="10"
      bg={[backgroundColor, "transparent", "transparent"]}
      maxWidth="1920px"
      borderBottom={["1px solid #c4bb75", "0px", "0px"]}
      // css={css`
      //   & {
      //     transition: all 0.5s ease;
      //   }
      // `}
    >
      <Logo setCompress={setCompress} />
      <Flex
        align="flex-end"
        justify="flex-end"
        pr={["4em", "10em", "20em"]}
        pb={[`${compress ? "2em" : "0.2em"}`, "0", ""]}
        mt={compress ? ["0", "0", "4em"] : "0"}
        // css={css`
        //   & {
        //     transition: all 0.5s ease;
        //   }
        // `}
      >
        <LanguageSwitch path={path} />
        <Menu path={path} />
      </Flex>
    </Flex>
  )
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
