import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Flex, Icon, Box } from "@chakra-ui/core"
import { useSpring, animated } from "react-spring"
import { Link } from "gatsby-plugin-react-i18next"
import { useScroll } from "react-use-gesture"

const AnimatedTextFrame = styled(animated.span)``

const AnimatedText = styled(animated.div)`
  line-height: 1em;

  @media (min-width: 48em) {
    & {
      transform: initial;
    }
  }

  @media (max-width: 47.99em) {
    & {
      position: relative !important;
      transform: none !important;
    }
  }
`

const AnimatedLogo = styled(animated.span)`
  @media (max-width: 47.99em) {
    & {
      transform: none;
    }
  }

  @media (min-width: 48em) {
    & {
      transform: none;
      position: relative !important;
    }
  }
`

const changingTitles = ["Gerhart", "Hauptmann", "Hiddensee"]

export function Logo({ setCompress: setCompressIn }) {
  const [titleIndex, setTitleIndex] = useState(0)
  const [compress, setCompress] = useState(false)

  const styleProps = useSpring({
    position: compress ? "absolute" : "relative",
    transform: compress
      ? "translate3d(0, -15vh, 0)"
      : "translate3d(0, -0vh, 0)",
  })

  // const stylePropsLogo = useSpring({
  //   position: compress ? "absolute" : "relative",
  //   transform: compress
  //     ? "translate3d(0, -15vh, 0)"
  //     : "translate3d(0, -0vh, 0)",
  // })

  useEffect(() => {
    window.setInterval(
      () => setTitleIndex(state => (state + 1) % changingTitles.length),
      3500
    )
  }, [])

  const bind = useScroll(
    ({ xy: [, y] }) => {
      if (y > 25) {
        setCompress(true)
        setCompressIn(true)
      }
      if (y <= 25) {
        setCompress(false)
        setCompressIn(false)
      }
    },
    {
      domTarget: typeof window !== "undefined" && window,
    }
  )

  useEffect(bind, [bind])

  return (
    <Link to="/">
      <Flex align="flex-end" pos="relative" pl={["4em", "0em", "0em"]}>
        <Box
          left={["17em", "0em", "-0.5em"]}
          top={["1.30em", "0em", "0em"]}
          h={["4em", "6.5em", "4.5em"]}
          px={["2em", "1.8em", "1.3em"]}
          pos={["absolute", "relative", "relative"]}
          mt={["1em", "1.8em", "1.3em"]}
          w="auto"
        >
          <AnimatedLogo style={styleProps}>
            <Icon
              name="logo"
              w="auto"
              h={["4em", "6.5em", "5em"]}
              pos="relative"
              bottom={["0", "0.50em", "0.99em"]}
              mt={compress ? ["0", "0", "1em"] : "0"}
            />
          </AnimatedLogo>
        </Box>

        <Flex
          position="relative"
          direction="column"
          fontSize={["5-5xl", "4xl", "3xl"]}
          justify="flex-end"
          h="50%"
          pt={["1em", "1.8em", "1.3em"]}
          pb={[`${compress ? "0.5em" : "0"}`, "0", ""]}
          left={["0", "0em", "-0.5em"]}
        >
          <AnimatedText style={styleProps}>
            {compress && (
              <Box as="span" d={["initital", "none", "none"]}>
                {changingTitles[titleIndex]}
              </Box>
            )}
            <Box
              as="span"
              d={[compress ? "none" : "initial", "initial", "initial"]}
            >
              Gerhart
            </Box>
          </AnimatedText>
          <AnimatedTextFrame style={styleProps}>
            <h1>Hauptmann</h1>
            <h1>Hiddensee</h1>
          </AnimatedTextFrame>
        </Flex>
      </Flex>
    </Link>
  )
}
