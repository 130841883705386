import React from "react"
import { Box } from "@chakra-ui/core"
import styled from "@emotion/styled"

const StyledBox = styled(Box)`
  /*position: fixed;*/
  position: absolute;
  right: 25px;
  top: 15em;
  z-index: 5;
  width: 38em;
  height: 38em;
  cursor: pointer;

  background: url('/GHH_Baubuch_STOERER_BAUBUCH_01.png') no-repeat;
  background-size: 100% auto;

  @media screen and (min-width: 48em) {
    top: 13em;
    right: 100px;
    width: 20em;
    height: 20em;
  }
  @media screen and (min-width: 75em) {
    top: 10em;
    width: 17.825em;
    height: 17.825em;
  }
`


const BannerLBTB = () => {

  const handleBannerClick = (e) => {
    e.preventDefault();
    e.target.href ? window.open(e.target.href) : window.open("http://www.das-literarische-bautagebuch.de");
  }

  return (
    <StyledBox onClick={handleBannerClick}>
    </StyledBox>
  )
}

export default BannerLBTB
